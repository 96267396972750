import { StringResources } from "./languageResource";
import {
    EnrollmentStatusEnum,
    EnrolmentTypeEnum,
    HousingCapacityEnum,
    HousingSortByEnum,
    RoomateTypeEnum,
    SecondaryEducationEnum,
} from "utils/enums";

export const translationsIs = {
    [StringResources.modal.close]: "Zatvori",
    [StringResources.modal.confirm]: "Potvrdi",

    [StringResources.login.subtitle]: "Pronađite svoj dom, daleko od doma.",
    [StringResources.login.title]: "Dobrodošli natrag!",
    [StringResources.login.formTitle]: "Prijavite se",
    [StringResources.login.formPasswordInputLabel]: "Lozinka",
    [StringResources.login.formUsernameInputLabel]: "Korisničko ime",
    [StringResources.login.formButtonSubmit]: "Prijavite se",
    [StringResources.login.forgotPassword]: "Zaboravili ste lozinku? Pošalji email.",
    [StringResources.login.formUsernameInputError]: "Korisničko ime je obavezno",
    [StringResources.login.formPasswordInputError]: "Lozinka je obavezna",

    [StringResources.resetPassword.subtitle]: "Pronađite svoj dom, daleko od doma.",
    [StringResources.resetPassword.title]: "Ponovno postavite svoju lozinku",
    [StringResources.resetPassword.formTitle]: "Unesite novu lozinku",
    [StringResources.resetPassword.formPasswordInputLabel]: "Lozinka",
    [StringResources.resetPassword.formPasswordInputError]: "Lozinka je obavezna",
    [StringResources.resetPassword.formConfirmPasswordInputLabel]: "Potvrdi lozinku",
    [StringResources.resetPassword.formConfirmPasswordInputError]: "Potvrđena lozinka je obavezna",
    [StringResources.resetPassword.saveButtonLabel]: "Spremi lozinku",
    [StringResources.resetPassword.formConfirmPasswordMatchError]: "Lozinka i potvrđena lozinka moraju biti iste",

    [StringResources.changePassword.subtitle]: "Pronađite svoj dom, daleko od doma.",
    [StringResources.changePassword.title]: "Promijenite svoju lozinku",
    [StringResources.changePassword.formTitle]: "Promijenite svoju staru lozinku",
    [StringResources.changePassword.formOldPasswordInputLabel]: "Stara lozinka",
    [StringResources.changePassword.formOldPasswordInputError]: "Stara lozinka je obavezna",
    [StringResources.changePassword.formPasswordInputLabel]: "Nova lozinka",
    [StringResources.changePassword.formPasswordInputError]: "Nova lozinka je obavezna",
    [StringResources.changePassword.formConfirmPasswordInputLabel]: "Potvrdi novu lozinku",
    [StringResources.changePassword.formConfirmPasswordInputError]: "Potvrđena lozinka je obavezna",
    [StringResources.changePassword.saveButtonLabel]: "Promijeni lozinku",
    [StringResources.changePassword.formConfirmPasswordMatchError]: "Nova lozinka i potvrđena lozinka moraju biti iste",

    [StringResources.forgotPassword.subtitle]: "Pronađite svoj dom, daleko od doma.",
    [StringResources.forgotPassword.title]: "Ponovno postavite svoju lozinku",
    [StringResources.forgotPassword.formTitle]: "Unesite svoj email",
    [StringResources.forgotPassword.formEmailInputLabel]: "Email",
    [StringResources.forgotPassword.formEmailRequiredInputError]: "Email je obavezan",
    [StringResources.forgotPassword.formEmailInputError]: "Email adresa mora biti važeća",
    [StringResources.forgotPassword.saveButtonLabel]: "Pošalji email",

    [StringResources.createAccount.subtitle]: "Pronađite svoj dom, daleko od doma.",
    [StringResources.createAccount.title]: "Dobrodošli u",
    [StringResources.createAccount.formNameInputLabel]: "Ime",
    [StringResources.createAccount.formLastNameInputLabel]: "Prezime",
    [StringResources.createAccount.formEmailInputLabel]: "Email adresa",
    [StringResources.createAccount.formPhoneInputLabel]: "Telefon",
    [StringResources.createAccount.formDateInputLabel]: "dd/mm/gggg",
    [StringResources.createAccount.formUsernameInputLabel]: "Korisničko ime",
    [StringResources.createAccount.formPasswordInputLabel]: "Lozinka",
    [StringResources.createAccount.formConfirmPasswordInputLabel]: "Potvrdi",
    [StringResources.createAccount.formCitizenshipInputLabel]: "Državljanstvo",
    [StringResources.createAccount.formButtonSubmit]: "Nastavi",
    [StringResources.createAccount.formNameInputRequiredError]: "Ime je obavezno",
    [StringResources.createAccount.formLastNameInputRequiredError]: "Prezime je obavezno",
    [StringResources.createAccount.formEmailInputRequiredError]: "Email adresa je obavezna",
    [StringResources.createAccount.formEmailInputError]: "Email adresa mora biti važeća",
    [StringResources.createAccount.formPhoneInputRequiredError]: "Telefon je obavezan",
    [StringResources.createAccount.formDateInputRequiredError]: "Datum rođenja je obavezan",
    [StringResources.createAccount.formUsernameInputRequiredError]: "Korisničko ime je obavezno",
    [StringResources.createAccount.formPasswordInputRequiredError]: "Lozinka je obavezna",
    [StringResources.createAccount.formConfirmPasswordInputRequiredError]: "Potvrđena lozinka je obavezna",
    [StringResources.createAccount.formConfirmPasswordMatchError]: "Lozinka i potvrđena lozinka moraju biti iste",
    [StringResources.createAccount.formCitizenshipInputRequiredError]: "Državljanstvo je obavezno",
    [StringResources.createAccount.formTerms]: "Potvrđujem da sam pročitao/la i razumio/la",
    [StringResources.createAccount.formTermsLink]: "uvjete korištenja",
    [StringResources.createAccount.formPrivacy]: "Potvrđujem da sam pročitao/la i razumio/la",
    [StringResources.createAccount.formPrivacyLink]: "policu privatnosti",
    [StringResources.createAccount.formCollegeInputRequiredError]: "Sveučilište je obavezno",
    [StringResources.createAccount.formEnrollmentTypeInputRequiredError]: "Vrsta upisa je obavezna",
    [StringResources.createAccount.formSecondaryEducationInputRequiredError]:
        "Završeno srednjoškolsko obrazovanje je obavezno",
    [StringResources.createAccount.formCollegeProgramInputRequiredError]: "Studijski program je obavezan",
    [StringResources.createAccount.formEnrollmentStatusInputRequiredError]: "Status upisa je obavezan",
    [StringResources.createAccount.formTermsAgreedInputRequiredError]: "Morate se složiti s uvjete korištenja",
    [StringResources.createAccount.formPrivacyAgreedInputRequiredError]: "Morate se složiti s policom privatnosti",
    [StringResources.createAccount.formCollegeInputPlaceholder]: "Sveučilište",
    [StringResources.createAccount.formEnrollmentTypeInputPlaceholder]: "Vrsta upisa",
    [StringResources.createAccount.formSecondaryEducationInputPlaceholder]: "Završeno srednjoškolsko obrazovanje",
    [StringResources.createAccount.formCollegeProgramInputPlaceholder]: "Studijski program",
    [StringResources.createAccount.formEnrollmentStatusInputPlaceholder]: "Status upisa",
    [StringResources.createAccount.createAccountSuccess]: "Uspješno kreiran korisnički račun",
    [StringResources.createAccount.createAccountError]: "Greška prilikom kreiranja korisničkog računa",

    [StringResources.header.navLinkHome]: "Početna",
    [StringResources.header.navLinkHousing]: "Stanovanje",
    [StringResources.header.navLinkAbout]: "O nama",
    [StringResources.header.navLinkContact]: "Kontakt",

    [StringResources.footer.text1]: "temeljni kapital",
    [StringResources.footer.text2]: "20.000,00 kuna,",
    [StringResources.footer.text3]: "uplaćen u cijelosti.",
    [StringResources.footer.primaryNavTitle]: "Navigacija",
    [StringResources.footer.secondaryNavTitle]: "Javite nam se",
    [StringResources.footer.primaryNavLinkHome]: "Početna",
    [StringResources.footer.primaryNavLinkHousing]: "Stanovanje",
    [StringResources.footer.primaryNavLinkAbout]: "O nama",
    [StringResources.footer.primaryNavLinkContact]: "Kontakt",
    [StringResources.footer.bottomText]: "Sva prava pridržana (C) Edudom",

    [StringResources.settings.navLinkAccount]: "Korisnički račun",
    [StringResources.settings.navLinkChangePassword]: "Promijenite lozinku",
    [StringResources.settings.navLinkLogout]: "Odjavi se",

    [StringResources.housing.heroTitle]: "Istražite stanove",
    [StringResources.housing.heroSubitle]: "Razmislite o svojim jedinstvenim potrebama",
    [StringResources.housing.heroText]: "Pronađite svoj dom danas",
    [StringResources.housing.filtersTitle]: "Detalji rezervacije",
    [StringResources.housing.filterAction]: "Filtriraj",
    [StringResources.housing.filterPriceRangeSlider]: "Raspon cijena (€*):",
    [StringResources.housing.moveInDate]: "Datum useljenja",
    [StringResources.housing.moveOutDate]: "Datum iseljenja",
    [StringResources.housing.housingList.sortBy]: " Sortiraj po:",

    [StringResources.housingDetails.heroTitlePart]: "Stan",
    [StringResources.housingDetails.heroSubitle]: "Korak bliže svom stanu",
    [StringResources.housingDetails.moveInDate]: "Datum useljenja",
    [StringResources.housingDetails.moveOutDate]: "Datum iseljenja",
    [StringResources.housingDetails.title]: "Provjerite vaše datume",
    [StringResources.housingDetails.action]: "Nastavi",

    [StringResources.housingReservation.heroTitlePart]: "Stan",
    [StringResources.housingReservation.heroTitle]: "Rezervacija",
    [StringResources.housingReservation.heroSubitle]: "Korak bliže svom stanu",
    [StringResources.housingReservation.moveInDate]: "Datum useljenja",
    [StringResources.housingReservation.moveOutDate]: "Datum iseljenja",
    [StringResources.housingReservation.roommateOption]: "Opcije soba:",
    [StringResources.housingReservation.haveRoommate]: "Imam cimera",
    [StringResources.housingReservation.findRoommate]: "Pronađi cimera",
    [StringResources.housingReservation.reserveBtn]: "Rezerviraj",
    [StringResources.housingReservation.checkboxLabel]: "Potvrđujem da sam pročitao/la i razumio/la",
    [StringResources.housingReservation.checkboxLink]: "ugovor o najmu",
    [StringResources.housingReservation.backLink]: "Natrag",
    [StringResources.housingReservation.roommate]: "Cimer:",
    [StringResources.housingReservation.formNameInputLabel]: "Ime",
    [StringResources.housingReservation.formLastNameInputLabel]: "Prezime",
    [StringResources.housingReservation.formEmailInputLabel]: "Email",
    [StringResources.housingReservation.formPhoneInputLabel]: "Telefon",
    [StringResources.housingReservation.roomAvailability]: "Dostupnost soba",
    [StringResources.housingReservation.dontWantRoomate]: "Ne želim cimera",
    [StringResources.housingReservation.reservationError]: "Pogreška pri rezervaciji",
    [StringResources.housingReservation.reservationSuccess]: "Uspješno ste rezervirali stan",

    [StringResources.profile.sraContactTitle]: "Kontakt informacije za odjel odnosa sa studentima (SRA)",
    [StringResources.profile.sraContactPhone]: "Broj telefona",
    [StringResources.profile.sraContactEmail]: "Email",
    [StringResources.profile.praContactTitle]: "Kontakt informacije za odjel odnosa s partnerima (PRA)",
    [StringResources.profile.praContactPhone]: "Broj telefona",
    [StringResources.profile.praContactEmail]: "Email",
    [StringResources.profile.infoBarCheckInDate]: "Datum prijave",
    [StringResources.profile.infoBarCheckOutDate]: "Datum odjave",
    [StringResources.profile.infoBarInstitution]: "Institucija",
    [StringResources.profile.infoBarProgram]: "Program",
    [StringResources.profile.tabResidency]: "Prebivalište",
    [StringResources.profile.tabHousing]: "Stanovanje",
    [StringResources.profile.tabEducation]: "Obrazovanje",
    [StringResources.profile.tabPayment]: "Plaćanje",
    [StringResources.profile.tabAccount]: "Korisnički račun",
    [StringResources.profile.tabGeneral]: "Općenito",
    [StringResources.profile.tabStudent]: "Student",
    [StringResources.profile.accountTabTitle]: "Osobne informacije",
    [StringResources.profile.accountFormUsernameInputLabel]: "Korisničko ime",
    [StringResources.profile.accountFormFirstNameInputLabel]: "Ime",
    [StringResources.profile.accountFormLastNameInputLabel]: "Prezime",
    [StringResources.profile.accountFormEmailInputLabel]: "Email",
    [StringResources.profile.accountFormFirstNameInputPlaceholder]: "Tvoje ime",
    [StringResources.profile.accountFormLastNameInputPlaceholder]: "Tvoje prezime",
    [StringResources.profile.accountFormUsernameInputPlaceholder]: "Tvoje korisničko ime",
    [StringResources.profile.accountFormEmailInputPlaceholder]: "Tvoja email adresa",
    [StringResources.profile.accountFormNameInputRequiredError]: "Ime je obavezno",
    [StringResources.profile.accountFormLastNameInputRequiredError]: "Prezime je obavezno",
    [StringResources.profile.accountFormEmailInputRequiredError]: "Email adresa je obavezna",
    [StringResources.profile.accountFormEmailInputError]: "Email adresa mora biti važeća",
    [StringResources.profile.accountFormUsernameInputRequiredError]: "Korisničko ime je obavezno",
    [StringResources.profile.accountFormPhoneInputRequiredError]: "Broj telefona je obavezan",
    [StringResources.profile.accountTermsConditionsCaption]: "Uvjeti korištenja",
    [StringResources.profile.accountPrivacyPolicyCaption]: "Pravila o privatnosti",
    [StringResources.profile.accountDeactivateAccountCaption]: "Deaktiviraj račun",
    [StringResources.profile.accountFormPhoneInputLabel]: "Broj telefona",
    [StringResources.profile.accountFormPhoneInputPlaceholder]: "Tvoj broj telefona",
    [StringResources.profile.accountFormPhoneInputError]: "Neispravan broj telefona",
    [StringResources.profile.accountSaveCaption]: "Spremi",
    [StringResources.profile.accountUploadCaption]: "Uredi",
    [StringResources.profile.paymentTabTitle]: "Informacije o plaćanju",
    [StringResources.profile.paymentTabIBAN]: "IBAN",
    [StringResources.profile.paymentTabReferenceNumber]: "Poziv na broj",
    [StringResources.profile.paymentTabAccommodation]: "Smještaj",
    [StringResources.profile.paymentTabDeposit]: "Polog",
    [StringResources.profile.paymentTabAmmount]: "Iznos",
    [StringResources.profile.paymentTabRent]: "Najam",
    [StringResources.profile.paymentTabUtilities]: "Režije",
    [StringResources.profile.paymentTabUtilitiesSeperately]: "Režije se plaćaju zasebno",
    [StringResources.profile.paymentTabAgencyFees]: "Agencijska naknada",
    [StringResources.profile.paymentTabAgencyFeeLandlord]: "Agencijska naknada + PDV",
    [StringResources.profile.paymentTabDepositMessage]:
        "Poslati potvrdu o plačanju na students@edudom.eu kako bi potvrdili rezervaciju.",
    [StringResources.profile.paymentTabTotal]: "Ukupno",
    [StringResources.profile.paymentTabDownloadPdf]: "Preuzmi PDF",
    [StringResources.profile.housingTabTitle]: "Nekretnine",
    [StringResources.profile.housingTabRoommates]: "Cimer",
    [StringResources.profile.housingTabFavoritesTitle]: "Lista favorita",
    [StringResources.profile.housingTabFavoritesFallbackMsg]: "Trenutno nemate favorita",
    [StringResources.profile.housingTabFavoritesAvailability]: "Dostupnost",
    [StringResources.profile.educationTabTableColumnDocument]: "Dokument",
    [StringResources.profile.educationTabTableColumnFormat]: "Format",
    [StringResources.profile.educationTabTableColumnSpecification]: "Specifikacije",
    [StringResources.profile.educationTabTableColumnPickup]: "Preuzimanje",

    [StringResources.home.heroTitle]: " pronađite svoj",
    [StringResources.home.heroSubitle]: "dom, daleko od doma...",
    [StringResources.home.heroActionLabel]: "Pročitajte više",
    [StringResources.home.contentTitle]: "Istražite Zagreb",
    [StringResources.home.contentSubtitle]: "Upoznajte se s glavnim gradom Hrvatske",
    [StringResources.home.contentBlockTitle1]: "Zrinjevac",
    [StringResources.home.contentBlockTitle2]: "Maksimir",
    [StringResources.home.contentBlockTitle3]: "Jarun",
    [StringResources.home.contentBlockTitle4]: "Bundek",
    [StringResources.home.contentBlockText1]:
        "Dobrodošli u Zrinjevac, skriveni dragulj u Zagrebu idealan za mlade putnike! Uživajte u čarobnim stazama parka, cvjetnim gredicama i živahnom glazbenom paviljonu. Opustite se, slušajte glazbu uživo i uživajte u društvu kolega studenata.",
    [StringResources.home.contentBlockText2]:
        "Hej, avanturisti! Park Maksimir je vaš sanjaran prostor u Zagrebu! Doživite čaroliju prirode uz raskošne krajolike, mirna jezera i raznovrsnu faunu. Pozovite prijatelje, organizirajte piknik i stvarajte nezaboravne trenutke u zagrljaju prirode.",
    [StringResources.home.contentBlockText3]:
        "Jezero Jarun je ultimativno igralište za mlade avanturiste! Uživajte u suncu, testirajte vještine u vodenim sportovima i uživajte u uzbudljivom noćnom životu. S pješčanim obalama i beskrajnim uzbuđenjima, Jarun nudi najbolje od oba svijeta.",
    [StringResources.home.contentBlockText4]:
        "Bundek Park u Zagrebu je vaše omiljeno odredište za uzbudljivu zabavu na otvorenom! Opuštajte se uz slikovitu jezersku obalu, zapalite roštilj na prekrasnim piknik prostorima i pridružite se užurbanom društvu na raznim događanjima.",
    [StringResources.home.eventsTitle]: "Događaji",
    [StringResources.home.partnersTitle]: "Partneri",
    [StringResources.home.partnersText]:
        "Kroz našu partnersku mrežu stvorili smo zajednicu koja olakšava potrebe studenata surađujući s našim partnerima i uključivanjem povratnih informacija studenata.",

    [StringResources.contact.title]: "Veselimo se vašem javljanju.",
    [StringResources.contact.subtitle]:
        "Bez obzira želite li nas nešto pitati ili trebate pomoć oko nekih prepreka, slobodno nas kontaktirajte.",
    [StringResources.contact.formTitle]: "Javite nam se",
    [StringResources.contact.formSubtitle]: "Ispunite obrazac i uskoro ćemo vam odgovoriti. ",
    [StringResources.contact.formNameInput]: "Vaše ime",
    [StringResources.contact.formEmailInput]: "Vaša email adresa",
    [StringResources.contact.formSubjectInput]: "Predmet",
    [StringResources.contact.formMessageInput]: "Vaša poruka",
    [StringResources.contact.formPhoneInput]: "Broj telefona",
    [StringResources.contact.formNameInputError]: "Ime je obavezno",
    [StringResources.contact.formEmailInputError]: "Email adresa mora biti važeća",
    [StringResources.contact.formEmailRequiredInputError]: "Email adresa je obavezna",
    [StringResources.contact.formSubjectInputError]: "Predmet je obavezan",
    [StringResources.contact.formPhoneInputError]: "Broj telefona je obavezna",
    [StringResources.contact.formMessageInputError]: "Poruka je obavezna",
    [StringResources.contact.formButtonSubmit]: "Pošalji",
    [StringResources.contact.formMessage]: "Odgovorit ćemo vam u roku od 1 do 2 radna dana.",

    [StringResources.about.title]: "Mi smo",
    [StringResources.about.subtitle]:
        "Studenti u Zagrebu mogu se suočiti s mnogim izazovima. Ova platforma je vaš vodič za povezivanje s zajednicom koja vas podržava.",
    [StringResources.about.visionTitlePart1]: "Naša",
    [StringResources.about.visionTitlePart2]: "Misija i Vizija",
    [StringResources.about.visionText]:
        "Nastojimo postati most između studenata i njihovih izazova kako bismo olakšali njihovu integraciju u novom domu. Kao tim, to postižemo pružajući studentima opcije smještaja koje odgovaraju njihovim potrebama.",
    [StringResources.about.studentsTitle]: "Odjel odonosa sa studentima",
    [StringResources.about.studentsText]:
        "Odjel za studentske odnose (SRA) vaš je kontakt za sve što se tiče integracije u studentski život u Zagrebu. Svaki student ima svog SRA koji vas vodi kroz vaš boravak u Zagrebu.",
    [StringResources.about.partnersTitle]: "Odjel odonsa sa partnerima",
    [StringResources.about.partnersText]:
        "Odjel za odnose s partnerima (PRA) ovdje je kako bi izgradio odnose s partnerskim institucijama. Odjel surađuje s partnerima i pružateljima usluga kako bi osigurao glatki proces za naše studente.",
    [StringResources.about.person1Position]: "Direktor i osnivač",
    [StringResources.about.person2Position]: "Operacijski menadžer",
    [StringResources.about.person3Position]: "Suradnik za odnose s partnerima",

    [StringResources.terms.title]: "Uvjeti ",
    [StringResources.terms.span]: "",
    [StringResources.terms.titlePart2]: " poslovanja",
    [StringResources.terms.scopeTitle]: "PODRUČJE PRIMJENE",
    [StringResources.terms.scopeText]:
        "Ovi uvjeti poslovanja sadrže prava i obveze koje u potpunosti prihvaćate aktiviranjem korisničkog računa na EDUDOM platformi. Kreiranje korisničkog računa podrazumijeva da ste pročitali i razumjeli Uvjete poslovanja te da ste pristali biti obvezani njima.",
    [StringResources.terms.definitionsTitle]: "DEFINICIJE",
    [StringResources.terms.definitionsText]:
        "U svrhu boljeg razumijevanja ovih Uvjeta poslovanja, sljedeće definicije će imati sljedeće značenje: ",
    [StringResources.terms.edudomTitle]: "Definicija",
    [StringResources.terms.edudomText]: "''EDUDOM''",
    [StringResources.terms.meaningTitle]: "Značenje",
    [StringResources.terms.meaningText]:
        "znači platforma/pretraživač na web domeni edudom.eu, u vlasništvu trgovačkog društva s ograničenom odgovornošću Edudom d.o.o. za usluge sa sjedištem u Zagrebu, Republika Hrvatska, registrirano pri Sudskom Registru Trgovačkog suda u Zagrebu pod brojem: 081457340.",
    [StringResources.terms.termsOfUseTitle]: "''Uvjeti poslovanja''",
    [StringResources.terms.termsOfUseTitleText]:
        "znači pravni akt koji predstavlja obvezujuće ugovorne odredbe između EDUDOM-a i Korisnika. Prihvaćanjem ovih Uvjeta poslovanja, Korisnik prihvaća pravila sadržana u njima. Mjerodavno pravo za ove Uvjete poslovanja je materijalno pravo Republike Hrvatske. Sva prava i obveze koje nisu izravno regulirane ovim Uvjetima poslovanja, regulirane su Zakonom o najmu stanova, Zakonom o obveznim odnosima i ostalim primjenjivim odredbama zakona Republike Hrvatske.",
    [StringResources.terms.userTitle]: "''Korisnik''",
    [StringResources.terms.userTitleText]:
        "znači korisnik koji ima registriran profil na platformi EDUDOM; student na studentskoj razmjeni/ boravku u Republici Hrvatskoj",
    [StringResources.terms.serviceTitle]: "''Usluga''",
    [StringResources.terms.serviceTitleText]:
        "znači usluga koja je ponuđena i pružena Korisniku tijekom registracijskog perioda na EDUDOM platformi i uživo",

    [StringResources.terms.servicesAndCompensationTitle]: "USLUGE I NAKNADE",
    [StringResources.terms.servicesAndCompensationText]: "EDUDOM svojim Korisnicima nudi sljedeće usluge:",
    [StringResources.terms.serviceItemA]:
        "uslugu pružanja podrške u pronalasku smještaja za vrijeme boravka u Republici Hrvatskoj, pri čemu Korisnik ima izbor i može izvršiti rezervaciju nekog od smještaja ponuđenog na platformi EDUDOM.",
    [StringResources.terms.serviceItemB]:
        "uslugu pružanja logističke i organizacijske podrške te usmjeravanja na licu mjesta - potpisivanje ugovora o najmu, ishođenje osobnog identifikacijskog broja, prijava boravišta u Republici Hrvatskoj, primopredaja unajmljenog stana",
    [StringResources.terms.serviceItemC]:
        "usluga pružanja logističke i organizacijske podrške s pripremom dokumentacije potrebne za valjan upis na fakultet",
    [StringResources.terms.serviceItemD]:
        "usluga pružanja ostalih informacija relevantnih za boravak u Republici Hrvatskoj",
    [StringResources.terms.servicesChargingText]:
        "Usluge navedene pod a) i b) su naplatne usluge, dok EDUDOM usluge navedene pod c) i d) ne naplaćuje svojim Korisnicima koji već koriste usluge navedene pod a) i b).",
    [StringResources.terms.compensationText]:
        "Naknada za Usluge navedene pod a) i b) će biti plaćena na način da će Korisnik izvršiti plaćanja naznačene najamnine za izabrani smještaj. Iznos naznačene najamnine uključuje najamninu uvećanu za dodatnu naknadu za Uslugu EDUDOM-a. Plaćanjem naznačene najamnine, Korisnik podmiruje i najamninu i mjesečnu naknadu za Usluge navedene gore pod a) i b).",
    [StringResources.terms.paymentProcedureText]:
        "Iznosi naznačenih najamnina će biti plaćani izravno EDUDOM-u, a EDUDOM preuzima punu odgovornost izvršavanja plaćanja vlasniku smještaja.",
    [StringResources.terms.additionalCostsText]:
        "Iznos naznačene najamnine ne uključuje troškove koji nisu izrijekom navedeni gore pod a) i b), što se odnosi bez ograničenja na sljedeće: bilo koji iznos štete koji bilo EDUDOM-u, bilo vlasniku smještaja može nastati zbog radnji ili propusta Korisnika, sudski/ administrativni troškovi, troškovi javnog bilježnika, troškovi sudskog tumača, troškovi useljenja i sl.",

    [StringResources.terms.servicesAndCompensationTitle]: "USLUGE I NAKNADE",
    [StringResources.terms.servicesAndCompensationText]: "EDUDOM svojim Korisnicima nudi sljedeće usluge:",
    [StringResources.terms.serviceItemA]:
        "uslugu pružanja podrške u pronalasku smještaja za vrijeme boravka u Republici Hrvatskoj, pri čemu Korisnik ima izbor i može izvršiti rezervaciju nekog od smještaja ponuđenog na platformi EDUDOM.",
    [StringResources.terms.serviceItemB]:
        "uslugu pružanja logističke i organizacijske podrške te usmjeravanja na licu mjesta - potpisivanje ugovora o najmu, ishođenje osobnog identifikacijskog broja, prijava boravišta u Republici Hrvatskoj, primopredaja unajmljenog stana",
    [StringResources.terms.serviceItemC]:
        "usluga pružanja logističke i organizacijske podrške s pripremom dokumentacije potrebne za valjan upis na fakultet",
    [StringResources.terms.serviceItemD]:
        "usluga pružanja ostalih informacija relevantnih za boravak u Republici Hrvatskoj",
    [StringResources.terms.servicesChargingText]:
        "Usluge navedene pod a) i b) su naplatne usluge, dok EDUDOM usluge navedene pod c) i d) ne naplaćuje svojim Korisnicima koji već koriste usluge navedene pod a) i b).",
    [StringResources.terms.compensationText]:
        "Naknada za Usluge navedene pod a) i b) će biti plaćena na način da će Korisnik izvršiti plaćanja naznačene najamnine za izabrani smještaj. Iznos naznačene najamnine uključuje najamninu uvećanu za dodatnu naknadu za Uslugu EDUDOM-a. Plaćanjem naznačene najamnine, Korisnik podmiruje i najamninu i mjesečnu naknadu za Usluge navedene gore pod a) i b).",
    [StringResources.terms.paymentProcedureText]:
        "Iznosi naznačenih najamnina će biti plaćani izravno EDUDOM-u, a EDUDOM preuzima punu odgovornost izvršavanja plaćanja vlasniku smještaja.",
    [StringResources.terms.additionalCostsText]:
        "Iznos naznačene najamnine ne uključuje troškove koji nisu izrijekom navedeni gore pod a) i b), što se odnosi bez ograničenja na sljedeće: bilo koji iznos štete koji bilo EDUDOM-u, bilo vlasniku smještaja može nastati zbog radnji ili propusta Korisnika, sudski/ administrativni troškovi, troškovi javnog bilježnika, troškovi sudskog tumača, troškovi useljenja i sl.",

    [StringResources.terms.activationTitle]: "AKTIVACIJA KORISNIČKOG RAČUNA",
    [StringResources.terms.ageRequirement]: "Aktiviranjem korisničkog računa Korisnik jamči da ima najmanje 18 godina.",
    [StringResources.terms.minorRegistration]:
        "Aktiviranjem korisničkog računa Korisnik jamči da ima najmanje 18 godina. Korisnici koji u vrijeme registracije imaju manje od 18 godina će imati tehničku mogućnost registracije. Međutim, nakon registracije će kontaktirani od EDUDOMA s ciljem koordinacije sklapanja odgovarajućih ugovora i/ ili ishođenja odgovarajućih isprava od zakonskih zastupnika Korisnika. U slučaju da student ne dostavi odgovarajuće ugovore i/ili isprave ovjerene od strane zakonskih zastupnika, EDUDOM će poništiti registraciju te brisati osobne podatke Korisnika.",
    [StringResources.terms.registrationProcedure]:
        "Kako bi kreirao korisnički račun, Korisnik je dužan kreirati korisničko ime i lozinku, pri čemu će lozinka biti poznata samo Korisniku. Kreiranje korisničkog računa je završeno slanjem dokaza o postojanju Korisnika, što se izvršava slanjem potvrde putem emaila.",
    [StringResources.terms.dataResponsibility]:
        "Korisnik preuzima punu odgovornost za točnost i zakonitost svih podataka objavljenih na profilu Korisnika.",
    [StringResources.terms.privacyPolicy]:
        "EDUDOM je kreirao i implementirao Politiku privatnosti koju Korisnici trebaju pročitati i prihvatiti prije registracije i koja je dostupna ovdje. Politika privatnosti definira poedinosti glede prava i obveza povezanih s osobnim podacima na profilu Korisnika.",
    [StringResources.terms.activationDuration]:
        "Nakon aktivacije korisničkog profila, profil na EDUDOM-u će biti aktivan na neodređeno vrijeme.",
    [StringResources.terms.inactivityProcedure]:
        "EDUDOM vodi računa o poštivanju načela zaštite osobnih podataka te će, u slučaju da je Korisnik tijekom cijele prethodne kalendarske godine bio neaktivan na profilu, brisati taj korisnički profil. U tom slučaju, EDUDOM zadržava pravo o tome upozoriti Korisnika unaprijed putem emaila, kako bi Korisniku dao priliku da poduzme aktivnosti na svojem korisničkom profilu i da na taj način izbjegne brisanje.",
    [StringResources.terms.accountDeletion]:
        "Korisnik svoj korisnički profil može ukloniti i sam, bilo putem opcija upravljanja svojim korisničkim profilom, bilo putem slanja email-a EDUDOM-u.",
    [StringResources.terms.postDeletionProcedure]:
        "Nakon brisanja korisničkog profila, EDUDOM će zadržati podatke o kontakt adresi za potrebe slanja newslettera, osim ako Korisnik ne prigovori takvoj obradi kontakt podataka i/ili se odjavi s newslettera.",
    [StringResources.terms.accountRecreation]:
        "Brisanje korisničkog profila, bilo od strane EDUDOM-a, bilo od strane Korisnika ne utječe na mogućnost kreiranja novog korisničkog profila od strane istog Korisnika u budućnosti.",
    [StringResources.terms.visitorAccess]:
        "Posjetitelji koji nisu registrirani korisnici će imati ograničen pristup sadržaju EDUDOM-a, u onoj mjeri koja je potrebna da bi posjetitelj mogao dobiti načelan dojam od sadržaju i značajkama usluge.",

    [StringResources.terms.liabilityTitle]: "ODGOVORNOST; NAKNADA ŠTETE",
    [StringResources.terms.liabilityText]: "EDUDOM odgovara za:",
    [StringResources.terms.liabilityListA]:
        "trajno osposobljavanje i edukaciju zaposlenika i partnera za pružanje Usluge na visokoj razini u skladu s ovim Uvjetima poslovanja i standardima struke;",
    [StringResources.terms.liabilityListB]:
        "pružanje Usluge sukladno definiranim rokovima i hodogramu naznačenom na platformi/ definiranom prilikom rezervacije smještaja;",
    [StringResources.terms.liabilityListC]: "tretiranje svih posjetitelja i/ili Korisnika na jednak način",
    [StringResources.terms.liabilityText2]:
        "EDUDOM će odgovarati samo za one propuste, naznačene iznad, ako su počinjeni namjerno ili zbog krajnje nepažnje. EDUDOM se neće smatrati odgovornim za eventualne propuste koji su rezultat obične nepažnje.",
    [StringResources.terms.nonLiabilityText3]: "EDUDOM ne odgovara za:",
    [StringResources.terms.nonLiabilityListA]: "pronalaženje odgovarajućeg smještaja Korisniku",
    [StringResources.terms.nonLiabilityListB]:
        "bilo kakve netočne podatke koje objavi Korisnik u svojem profilu ili koji se nalaze u prezentaciji ponuđenog smještaja",
    [StringResources.terms.nonLiabilityListC]: "pravno zastupanje Korisnika",
    [StringResources.terms.nonLiabilityListD]:
        "potencijalno nezakonito ili neuredno izvršavanje obveza Korisnika prema vlasniku smještaja i obratno",
    [StringResources.terms.nonLiabilityListE]:
        "bilo koju vrstu neetičnog i/ili neprofesionalnog postupanja Korisnika ili vlasnika nekretnine koje bi na bilo koji način bilo povezano s EDUDOM-om",
    [StringResources.terms.nonLiabilityListF]:
        "bilo koju neusklađenost postupanja Korisnika i/ili vlasnika smještaja s regulatornim zahtjevima",
    [StringResources.terms.nonLiabilityListG]:
        "privremenu nedostupnost platforme zbogtehničkog održavanja, instaliranja/modifikacija.",
    [StringResources.terms.liabilityText3]:
        "Korisnik u bilo kojem slučaju preuzima punu odgovornost za sklapanje ugovornog odnosa ili za ulazak u bilo koji drugi pravni odnos s vlasnikom smještaja i/ili za izvršavanje bilo kojih prava i/ili obveza koji su na bilo koji način motivirani aktivnostima na EDUDOM platformi.",
    [StringResources.terms.liabilityText4]:
        "Neovisno o činjenici da će Korisnik isključivo odgovarati za svoje radnje koje su na bilo koji način povezane s EDUDOM platformom, EDUDOM ohrabruje Korisnike da prijave bilo koje neugodno iskustvo koje bi na bilo koji način bilo povezano s EDUDOM platformom. EDUDOM pridržava pravo ispitati takvu prijavu u donijeti neovisnu odluku o nastavku/prekidu suradnje s prijavljenom osobom, uzimajući u obzir kako okolnosti zbog kojih je podnesena prijava mogu utjecati na kvalitetu Usluge ili reputaciju EDUDOM-a-",
    [StringResources.terms.liabilityText5]:
        "U slučaju da bilo koja treća strana, uključujući ali ne ograničavajući se na regulatorna tijela, utvrdi da bi EDUDOM bio odgovoran za bilo koju od okolnosti navedenih u ovom članku kao one okolnosti za koje EDUDOM ne odgovara, Korisnik će, po prvom pozivu EDUDOM-a preuzeti odgovornost za sve novčane i/ili nenovčane tražbine koje bi treća osoba mogla zahtijevati od EDUDOM-a. U slučaju kad bi EDUDOM, zbog bilo kojeg razloga, bio obvezan platiti bilo koji iznos naknade zbog nastanka okolnosti koje su ovim člankom utvrđene kao okolnosti za koje EDUDOM ne odgovara, Korisnik zbog kojeg su nastale te okolnosti će biti dužan EDUDOM-u naknaditi štetu nastalu na taj način, uvećanu za iznos kamate.",

    [StringResources.terms.terminationTitle]: "JEDNOSTRANI RASKID",
    [StringResources.terms.terminationText1]:
        "Usluga prihvaćena na daljinu (online) može biti jednostrano raskinuta od strane Korisnika bez navođenja razloga za takav raskid. Korisnik može izjaviti jednostrani raskid u roku od 14 dana od dana kad je rezervirao Uslugu, odnosno od dana kad je izvršio rezervaciju smještaja na EDUDOM platformi.",
    [StringResources.terms.terminationText2]:
        "Obavijest o jednostranom raskidu treba biti poslana EDUDOM-u putem email adrese ed@edudom.eu, nedvosmisleno obavještavajući EDUDOM o tome da je Usluga jednokratno raskinuta.",
    [StringResources.terms.terminationText3]:
        "U slučaju kad Korisnik jednostrano raskine Uslugu EDUDOM je dužan Korisniku izvršiti povrat uplaćene naknade i depozita. EDUDOM je dužan izvršiti izvršiti povrat transakcijom putem banke te samo u iznosu plaćene naknade i depozita. Korisnik snosi troškove koji se odnose na međunarodne bankovne transakcije, troškove konverzije i sl. EDUDOM je dužan izvršiti povrat plaćanja u roku od 14 dana od kad primi obavijest o jednostranom raskidu od Korisnika.",
    [StringResources.terms.terminationText4]:
        "U slučaju kad Korisnik, prije isteka 14 dana od dana kreiranja rezervacije online, potpisao ugovor o najmu na licu mjesta (uživo), Korisnik gubi pravo na jednostrani raskid ugovora na temelju ovog članka.",
    [StringResources.terms.customerServiceTitle]: "KORISNIČKA PODRŠKA; PRUŽANJE INFORMACIJA; PRIGOVOR POTROŠAČA",
    [StringResources.terms.customerServiceText]:
        "EDUDOM je korisnicima dostupan putem sljedeće email adrese: ed@edudom.eu. U slučaju da Korisnici iz bilo kojeg razloga nisu zadovoljni Uslugom, na navedenu adresu mogu uputiti i prigovor potrošača. EDUDOM će odgovoriti na sva pitanja Korisnika, uključujući i potrošačke prigovore, u najkraćem mogućem roku koji neće biti dulji od 15 dana od dana primitka upita. Sva komunikacija, pružanje informacija i sl. će biti izvršavano pomoću kontakt podataka koje je Korisnik unio u svoj korisnički profil. U slučaju promjene bilo kojih od kontakt podataka, Korisnik je dužan bez odgode izvršiti potrebne prilagodbe svojeg korisničkog profila.",
    [StringResources.terms.disputeResolutionTitle]: "RJEŠAVANJE SPOROVA",
    [StringResources.terms.disputeResolutionText]:
        "EDUDOM i Korisnik će, u dobroj vjeri i na prijateljski način nastojati riješiti sve sporove koji bi proizlazili ili bi bili u vezi s ovim Uvjetima poslovanja i/ili izvršavanjem prava i obveza koja proizlaze ili su u vezi s Uslugom i/ili, uključujući ali se ne ograničavajući, u vezi s ništetnošću, ugovornim povredama ili raskidom te bilo kojim pravnim posljedicama koje proizlaze iz toga. Ako je Korisnik rezident države članice EU-a, Lihtenštajna, Norveške ili Islanda, Korisnik može započeti postupak mirnog rješavanja spora na platformi  https://ec.europa.eu/consumers/odr/main/?event=main.home2.show. U slučajevima kad mirno rješenje spora nije moguće sukladno nekom od prethodnih stavaka ovog članka, EDUDOM i Korisnik će riješiti spor pred nadležnim sudom u Zagrebu, Republika Hrvatska.",
    [StringResources.terms.miscellaneousTitle]: "ZAVRŠNE ODREDBE",
    [StringResources.terms.miscellaneousText]:
        "Ovi Uvjeti poslovanja su redovno ažurirani s ciljem pojašnjavanja pojedinih odredbi u slučaju da je to potrebno i/ili u svrhu prilagodbi izmjenama i/ili poboljšicama Usluge. Sve izmjene i dopune ovih Uvjeta poslovanja će biti kreirane i objavljene na jednak način kao i ovi Uvjeti poslovanja. Svi Korisnici će biti informirani o izmjenama Uvjeta poslovanja bilo prvom sljedećem posjetom platformi EDUDOM, bilo putem emaila. Svaka izmjena i dopuna ovih Uvjeta poslovanja će naznačavati nadnevke od kad se izmjene i dopune primjenjuju.",
    [StringResources.terms.termsPublishDate]:
        "Ovi Uvjeti poslovanja su kreirani i objavljeni na dan 31. ožujka 2023. godine.",

    [StringResources.privacy.privacyTitle]: "Zaštita osobnih podataka",
    [StringResources.privacy.privacyTitle2]: "ZAŠTITA PRIVATNOSTI KORISNIKA",
    [StringResources.privacy.privacyText1]:
        "Osobne podatke Korisnika koje EDUDOM obrađuje u okviru poslovne suradnje s Korisnikom, EDUDOM prikuplja neposredno od Korisnika, odnosno EDUDOM raspolaže onim osobnim podacima koje mu je Korisnik sam dao za potrebe suradnje.",
    [StringResources.privacy.privacyText2]:
        "EDUDOM obrađuje određene osobne podatke Korisnika na temelju zakonske obveze (npr. vođenje posredničkih dnevnika ili obavljanje poslova u vezi sa zakonskom regulativom glede sprječavanja pranja novca i financiranja terorizma).",
    [StringResources.privacy.privacyText3]:
        "Voditelj obrađuje određene osobne podatke Klijenta radi ispunjenja ugovorne obveze, odnosno kako bi mogao izvršiti uslugu posredovanja (npr. identifikacijski i kontakt podaci Korisnika – ime, prezime, adresa, OIB, email i broj telefona)",
    [StringResources.privacy.privacyText4]:
        "EDUDOM određene osobne podatke Korisnika obrađuje na temelju legitimnog interesa. EDUDOM ima legitimni interes klijentu upućivati emailove informativnog sadržaja koji je u vezi s predmetom suradnje između Korisnika i EDUDOMA i mailove u svrhu nuđenja usluga istih ili sličnih onima koji su predmet poslovne suradnje između njih, pod uvjetom da Korisnik nije prigovorio obradi njegovih kontakt podataka u te svrhe.",
    [StringResources.privacy.privacyText5]:
        "EDUDOM surađuje s obrazovnom ustanovom u koju je Korisnik upisan radi pružanja usluge potpune integracije Korisnika kod njegovog doseljenja u Republiku Hrvatsku. Stoga EDUDOM obrazovnoj ustanovi prosljeđuje osobne podatke studenta, i to ime i prezime, status rezervacije smještaja, kao i podatak je li student sklopio ugovor o najmu stana, sve s ciljem da djelatnici obrazovne ustanove studentu mogu pružiti podršku prilikom prijave boravišta u Republici Hrvatskoj, pod uvjetom da Korisnik nije prigovorio obradi njegovih podataka u te svrhe.",
    [StringResources.privacy.privacyText6]:
        "U slučajevima kad je za realizaciju posla potrebno sastavljanje ugovor i/ili druge vrste isprava ili poduzimanje kojih drugih radnji od strane odvjetnika, osobni podaci potrebni za sastavljanje takvih isprava odnosno za poduzimanje takvih radnji će u tu svrhu biti dostavljeni odvjetniku.",
    [StringResources.privacy.privacyText7]:
        "EDUDOM dostavlja osobne podatke Korisnika vanjskom knjigovodstvenom servisu u svrhu ispunjenja obveza koje EDUDOM ima na temelju Zakona o računovodstvu i na temelju drugih knjigovodstvenih propisa, a s kojim je knjigovodstvenim servisom sklopio odgovarajući ugovor kojim se reguliraju pitanja zaštite osobnih podataka.",
    [StringResources.privacy.privacyText8]:
        "EDUDOM ne prenosi osobne podatke Klijenta drugim zemljama ni međunarodnim organizacijama, odnosno ne prenosi osobne podatke Korisnika izvan Republike Hrvatske.",
    [StringResources.privacy.privacyText9]:
        "Osobne podatke Korisnika EDUDOM dostavlja trećim osobama kao primateljima - državnim tijelima -  u slučajevima kad EDUDOM za to ima zakonsku obvezu",
    [StringResources.privacy.privacyText10]:
        "EDUDOM će čuvati osobne podatke Korisnika za vrijeme trajanja poslovne suradnje, nakon čega će ih brisati, osim ako ima zakonsku obvezu čuvanja određene kategorije osobnih podataka kroz dulje vrijeme, ako mu je određena kategorija osobnih podataka potrebna radi ostvarivanja prava sudskim putem ili ako mu je Korisnik dao privolu za obradu određene kategorije osobnih podataka u određenu svrhu.",
    [StringResources.privacy.privacyText11]:
        "Korisnik u svakom trenutku od EDUDOM-a može tražiti pristup svojim osobnim podacima kojima EDUDOM raspolaže, kao i pravo zahtijevati od EDUDOM-a ispravak ili brisanje osobnih podataka, ograničavanje obrade osobnih podataka kao i pravo uložiti prigovor glede obrade osobnih podataka.",

    [StringResources.rent.title]: "Rent",
    [StringResources.rent.span]: "agreement",

    [StringResources.privacy.customerServiceTitle]: "",
    [StringResources.privacy.customerServiceText1]: "",
    [StringResources.privacy.customerServiceText2]: "",
    [StringResources.privacy.customerServiceText3]: "",
    [StringResources.privacy.customerServiceText4]: "",

    [StringResources.gdprConsent.text]:
        "Klikom na “Prihvati sve kolačiće” pristajete na pohranjivanje kolačića na vaš uređaj radi poboljšanja navigacije web-mjesta, analize korištenja web-mjesta i pomoći u našim marketinškim naporima.",
    [StringResources.gdprConsent.acceptAction]: "Prihvati sve kolačiće",
    [StringResources.gdprConsent.settingsAction]: "Postavke kolačića",
    [StringResources.gdprConsent.necessaryCookies]: "Neophodni kolačići",

    [StringResources.enums.roomateTypeEnum[RoomateTypeEnum.Student]]: "Student",
    [StringResources.enums.roomateTypeEnum[RoomateTypeEnum.NotAStudent]]: "Nije student",
    [StringResources.enums.housingCapacity[HousingCapacityEnum.Single]]: "Jednokrevetna",
    [StringResources.enums.housingCapacity[HousingCapacityEnum.Double]]: "Dvokrevetna",
    [StringResources.enums.housingCapacity[HousingCapacityEnum.Triple]]: "Trokreventna",

    [StringResources.enums.enrolmentType[EnrolmentTypeEnum.StateExam]]: "Državni ispit",
    [StringResources.enums.enrolmentType[EnrolmentTypeEnum.University]]: "Sveučilište",
    [StringResources.enums.secondaryEducation[SecondaryEducationEnum.Croatia]]: "Hrvatska",
    [StringResources.enums.secondaryEducation[SecondaryEducationEnum.EU]]: "EU",
    [StringResources.enums.secondaryEducation[SecondaryEducationEnum.NonEU]]: "Nije EU",
    [StringResources.enums.enrollmentStatus[EnrollmentStatusEnum.Exchange]]: "Razmjena",
    [StringResources.enums.enrollmentStatus[EnrollmentStatusEnum.LongTerm]]: "Dugoročno",
    [StringResources.enums.enrollmentStatus[EnrollmentStatusEnum.Transfer]]: "Prijenos",

    [StringResources.enums.housingSort[HousingSortByEnum.Price]]: "Cijeni",
    [StringResources.enums.housingSort[HousingSortByEnum.DistanceToUni]]: "Udaljenost od kampusa",
    [StringResources.enums.housingSort[HousingSortByEnum.Single]]: "Jednokrevetna",
    [StringResources.enums.housingSort[HousingSortByEnum.Double]]: "Dvokrevetna",
    [StringResources.enums.housingSort[HousingSortByEnum.Triple]]: "Trokreventna",
};
