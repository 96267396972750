import React from "react";
import { Dropdown } from "react-bootstrap";
import { BellIcon, CancelCircleIcon, CheckCircleIcon, WarningIcon, CancelIcon } from "components/icons";
import "./notifications.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { removeNotification } from "redux/reducers/userReducer";
import { INotification } from "redux/models/userModels";
import { useNavigate } from "react-router-dom";
import { IUserRoleEnum } from "utils/enums";

const Notifications = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const notifications = useSelector((state: RootState) => state.user.notifications);
    const user = useSelector((state: RootState) => state.user.userData);

    const removeNotificationHandler = (notification: INotification) => {
        return () => {
            dispatch(removeNotification(notification.id));
        };
    };

    const notificationClickHandler = (notification: INotification) => {
        return () => {
            if (user?.role == IUserRoleEnum.Student) {
                if (notification.action == "enrollment") {
                    navigate("/my-profile#enrollment");
                }
                if (notification.action == "residency") {
                    navigate("/my-profile#residency");
                }
            }
        };
    };

    return (
        <Dropdown className="notifications-dropdown" align={"end"} autoClose={false}>
            <Dropdown.Toggle className="notifications-dropdown__toggle-action">
                <BellIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu className="notifications-dropdown__menu">
                {notifications && notifications.length > 0 ? (
                    notifications.map(notification => {
                        return (
                            <Dropdown.Item
                                className="notifications-dropdown__menu-item info"
                                onClick={notificationClickHandler(notification)}
                                key={notification.id}
                            >
                                <BellIcon className="notifications-dropdown__menu-item-icon" />
                                <div className="notifications-dropdown__menu-item-content">
                                    <p className="notifications-dropdown__menu-item-title">
                                        {notification.action && (notification.action.charAt(0).toUpperCase() + notification.action.slice(1))} -
                                        Information
                                    </p>
                                    <p className="notifications-dropdown__menu-item-text">{notification.text}</p>
                                    <p className="notifications-dropdown__menu-item-date"></p>
                                </div>

                                <button
                                    type="button"
                                    className="notifications-dropdown__menu-item-action"
                                    onClick={removeNotificationHandler(notification)}
                                >
                                    <CancelIcon />
                                </button>
                            </Dropdown.Item>
                        );
                    })
                ) : (
                    <p className="notifications-dropdown__menu-item-text">No notifications</p>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default Notifications;
