import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StringResources } from "utils/language/languageResource";
import logo from "assets/images/logo.png";
import { CancelIcon, HamburgerMenuIcon, UserIcon } from "components/icons";
import LanguageSelector from "components/languageSelector/languageSelector";
import Settings from "./settings/settings";
import Notifications from "./notifications/notifications";
import { LoginStateEnum } from "utils/enums";
import { PATHS } from "utils/routing/paths";
import "./appHeader.scss";
import { UserRole, UserType } from "../../redux/models/userModels";

const AppHeader = () => {
    const { t } = useTranslation();
    const [isMainNavOpen, setIsMainNavOpen] = useState(false);
    const authDataState = useSelector((state: RootState) => state.auth.loginState);
    const studentData = useSelector((state: RootState) => state.user.details);

    const toggleMobileNavigation = () => {
        setIsMainNavOpen(!isMainNavOpen);
    };

    const closeMobileNavigation = () => {
        setIsMainNavOpen(false);
    };

    return (
        <>
            <header className="app-header">
                <div className="container">
                    <div className="row">
                        <div className="col app-header__wrap">
                            <Link to={PATHS.Global.Dashboard}>
                                <img src={logo} alt="Edudom" className="app-header__logo-img" />
                            </Link>

                            <ul
                                className={classNames("app-header__nav", {
                                    "app-header__nav--is-open": isMainNavOpen,
                                })}
                            >
                                <li className="app-header__nav-list-item">
                                    <Link to={PATHS.Global.Dashboard} className="app-header__nav-action">
                                        {`${t(StringResources.header.navLinkHome)}`}
                                    </Link>
                                </li>
                                {authDataState === LoginStateEnum.LoggedIn && studentData?.type === UserType.STUDENT && (
                                    <li className="app-header__nav-list-item">
                                        <Link to={PATHS.Portal.Housing} className="app-header__nav-action">
                                            {`${t(StringResources.header.navLinkHousing)}`}
                                        </Link>
                                    </li>
                                )}
                                <li className="app-header__nav-list-item">
                                    <Link to={PATHS.Global.AboutUs} className="app-header__nav-action">
                                        {`${t(StringResources.header.navLinkAbout)}`}
                                    </Link>
                                </li>
                                <li className="app-header__nav-list-item">
                                    <Link to={PATHS.Global.ContactUs} className="app-header__nav-action">
                                        {`${t(StringResources.header.navLinkContact)}`}
                                    </Link>
                                </li>

                                {authDataState === LoginStateEnum.LoggedIn &&
                                    studentData?.roles.includes(UserRole.ADMIN) && (
                                        <li className="app-header__nav-list-item">
                                            <Link to={"/admin"} className="app-header__nav-action">
                                                Admin
                                            </Link>
                                        </li>
                                    )}
                            </ul>

                            <div className="app-header__secondary-list">
                                {authDataState === LoginStateEnum.LoggedIn ? (
                                    <>
                                        <Notifications />
                                        <Settings />
                                    </>
                                ) : (
                                    <Link to="/login" className="app-header__login-action">
                                        <UserIcon />
                                    </Link>
                                )}

                                <LanguageSelector />
                            </div>

                            <button
                                className={classNames("app-header__toggle-mobile-nav-action", {
                                    "app-header__toggle-mobile-nav-action--is-open": isMainNavOpen,
                                })}
                                onClick={toggleMobileNavigation}
                            >
                                <HamburgerMenuIcon className="app-header__menu-icon" />
                                <CancelIcon className="app-header__cancel-icon" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <div
                className={classNames("app-header__overlay", {
                    "app-header__overlay--is-visible": isMainNavOpen,
                })}
                onClick={closeMobileNavigation}
            ></div>
        </>
    );
};

export default AppHeader;
