import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StringResources } from "utils/language/languageResource";
import { SettingsIcon, UserIcon, LockIcon, LogoutIcon } from "components/icons";
import { PATHS } from "utils/routing/paths";
import "./settings.scss";
import { removeUserLocalStorageData } from "utils/storageActions";
import { RootState, useAppDispatch } from "redux/store";
import { reduxAction } from "utils/constants";
import { useSelector } from "react-redux";
import { IUserRoleEnum } from "utils/enums";
import {UserType} from "../../../redux/models/userModels";

const Settings = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const studentData = useSelector((state: RootState) => state.user.userData);
    const loggedUser = useSelector((state: RootState) => state.auth.loggedUser);

    const logout = () => {
        removeUserLocalStorageData();
        dispatch({ type: reduxAction.resetStore });
        navigate(PATHS.NonLoggedIn.Login);
    };

    const resolveAccountPath = () => {
        const {type} = loggedUser;
        if (type === UserType.LANDLORD) {
            return PATHS.Portal.LanlordProfile
        } else if (type === UserType.UNIVERSITY) {
            return PATHS.Portal.UniversityProfile
        }

        return PATHS.Portal.MyProfile
    }

    // const accountPath =
    //     studentData?.role === IUserRoleEnum.Student
    //         ? PATHS.Portal.MyProfile
    //         : studentData?.role === IUserRoleEnum.Landlord
    //         ? PATHS.Portal.LanlordProfile
    //         : PATHS.Portal.UniversityProfile;

    return (
        <Dropdown align={"end"} className="settings-dropdown">
            <Dropdown.Toggle className="settings-dropdown__toggle-action">
                <SettingsIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu className="settings-dropdown__menu">
                <Dropdown.Item as={Link} to={resolveAccountPath()} className="settings-dropdown__menu-item">
                    <UserIcon />
                    {`${t(StringResources.settings.navLinkAccount)}`}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={PATHS.Portal.ChangePassword} className="settings-dropdown__menu-item">
                    <LockIcon />
                    {`${t(StringResources.settings.navLinkChangePassword)}`}
                </Dropdown.Item>
                <Dropdown.Item as="button" className="settings-dropdown__menu-item" onClick={logout}>
                    <LogoutIcon />
                    {`${t(StringResources.settings.navLinkLogout)}`}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default Settings;
